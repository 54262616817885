
import { AppUserClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Screenset extends Vue {

    mounted() {
        gigya.accounts.showScreenSet({
            screenSet: "Aprilia-2024-Community-RegistrationLogin",
            containerID: "gigya-login-one-column",
            startScreen: "gigya-login-one-column"
        });

        gigya.accounts.addEventHandlers({
            onLogin: function(ris) {
                AppUserClient.checkIfAlreadyRegisterByEmail(ris.profile.email)
                .then(x => {
                    var languageCredential = new OM.LanguageCredential();
                    languageCredential.preferredCulture = "it-IT";
                    languageCredential.username = ris.profile.email;
                    languageCredential.password = "123456";

                    if(x) {
                        AppUserClient.login(languageCredential)
                        .then(x => {
                            AppUserClient.updateUserType("secretPWd07072024", x.identifier, ris.data.FO_Bearacer)
                            .then(voidRis => {
                                LoginServices.LoginCallback(x.token, x.refreshToken, this.selectedLanguage);
                            })
                        })
                    }
                    else {
                        var updateModel = new OM.UpdateUserInfoVM();
                        updateModel.email = ris.profile.email;
                        updateModel.name = ris.profile.firstName;
                        updateModel.surname = ris.profile.lastName;
                        updateModel.id = ris.profile.UID;
                        updateModel.type = ris.data.FO_Bearacer;
                        LoginServices.RegisterNewUser(languageCredential, updateModel, false);
                    }
                })
            }
        });
    }

}
